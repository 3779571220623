<template>
  <div class="container-fluid">
    <Header />

    <div class="mx-auto mt-1 content-outer-container">
      <div class="wrap-Announcement">
        <div class="mx-auto content-inner-container border-header">
          <div class="col-12">
            <div class="row">
              <div class="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12">
                <a-button
                  class="d-flex align-center justify-center"
                  @click="goto('Announcement')"
                >
                  <a-icon type="arrow-left" />
                </a-button>
              </div>
              <div
                v-if="data"
                class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12"
              >
                <div class="title-header">
                  <span>{{ data.Title }}</span>
                </div>
                <div class="tag-preview"></div>
                <div v-if="data.CoverImages" class="body-preview my-5">
                  <div class="box-img-preview">
                    <img
                      :src="coverImages(data.CoverImages)"
                      class="h-100 w-100"
                      alt=""
                    />
                  </div>
                  <div
                    v-if="data.Contents"
                    class="box-content mt-4"
                    v-html="data.Contents"
                  ></div>
                  <div class="box-content date mt-4">
                    {{ dateFormatData(data.StartDate) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import Account from '@/helper/AccountHelper.js'
import BzbsAnnouncement from '@/core/Announcement/service/BzbsAnnouncement'

export default {
  name: 'AnnouncementDetail',
  mixins: [Mixin],
  components: {
    Header,
  },
  data() {
    return {
      partitionKey: null,
      rowKey: null,
      data: {},
    }
  },
  created() {
    this.handleFooter(true)
    Account.bzbsAnalyticTracking(
      'announcement_detail',
      'announcement',
      'view_detail_announcement',
      'on view',
    )
    this.init()
  },
  methods: {
    init() {
      console.log('route : ', this.$route)
      if (this.$route.params.partitionKey && this.$route.params.rowKey) {
        this.partitionKey = this.$route.params.partitionKey
        this.rowKey = this.$route.params.rowKey
        if (this.partitionKey && this.rowKey) {
          this.getAnnouncementById(this.partitionKey, this.rowKey)
        } else {
          this.$router.push({ name: 'Announcement' })
        }
      } else {
        this.$router.push({ name: 'Announcement' })
      }
    },
    getAnnouncementById(partitionKey, rowKey) {
      console.log('getAnnouncementById : ', partitionKey, rowKey)
      this.data = {}
      return new Promise((resolve, reject) => {
        BzbsAnnouncement.apiAnnouncementDetail(partitionKey, rowKey)
          .then(res => {
            console.log('apiAnnouncementDetail : ', res.data)
            if (res.data.data) {
              this.data = res.data.data
            }
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    dateFormatData(value) {
      if (value) {
        var toDate = moment.unix(value).utc()
        var date = moment(toDate).format('DD/MM/YYYY')
        return date
      } else {
        return ''
      }
    },
    coverImages(Images) {
      if (Images) {
        let imgList = JSON.parse(Images)
        let img = imgList[0].Url + '?v=' + new Date().getTime()
        return img
      } else {
        return null
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/style/main.scss';
@import '../styles/announcement_main.scss';
</style>
